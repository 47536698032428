// Import dashjs because npm package dows not work with OvenPlayer currently
import '../vendor/dash.all.min';

// Import OvenPlayer stuff (not sure what's required of this whole stuff)
// import '../vendor/ovenplayer/downloader-0.9.0';
import '../vendor/ovenplayer/ovenplayer';
import '../vendor/ovenplayer/ovenplayer.provider.DashProvider~ovenplayer.provider.HlsProvider~ovenplayer.provider.Html5~ovenplaye~2ec193ac-0.9.0';
import '../vendor/ovenplayer/ovenplayer.provider.DashProvider-0.9.0';
import '../vendor/ovenplayer/ovenplayer.provider.HlsProvider-0.9.0';
import '../vendor/ovenplayer/ovenplayer.provider.Html5-0.9.0';
import '../vendor/ovenplayer/ovenplayer.provider.RtmpProvider-0.9.0';
import '../vendor/ovenplayer/ovenplayer.provider.WebRTCProvider-0.9.0';
import '../vendor/ovenplayer/ovenplayer.sdk';
import '../vendor/ovenplayer/smiparser-0.9.0';
import '../vendor/ovenplayer/vendors~downloader-0.9.0';
import '../vendor/ovenplayer/vttparser-0.9.0';


document.addEventListener('DOMContentLoaded', init);

function init() {
  updatePlayerSize();
  window.addEventListener('resize', updatePlayerSize);

  const player = OvenPlayer.create("bs-twitch-player", {
    sources: [
      {
        type: "dash",
        lowLatency: true,
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_fhdo/manifest_ll.mpd",
        label: "FHD DASH Low Latency"
      },
      {
        type: "dash",
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_fhdo/manifest.mpd",
        label: "FHD DASH"
      },
      {
        type: "hls",
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_fhdo/playlist.m3u8",
        label: "FHD HLS"
      },
      {
        type: "dash",
        lowLatency: true,
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_hdo/manifest_ll.mpd",
        label: "HD DASH Low Latency"
      },
      {
        type: "dash",
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_hdo/manifest.mpd",
        label: "HD DASH"
      },
      {
        type: "hls",
        framerate: 30,
        file: process.env.STREAM_URL + "/live/test_hdo/playlist.m3u8",
        label: "HD HLS"
      }
    ]
  });

  player.on("error", function (error) {
    console.log(error);
  });
}

/**
 * Make sure, the player adapts its width to the current height of the player box.
 * The height of the player box is usually the maximum possible height so that the maximum area of the screen is filled by the video.
 */
function updatePlayerSize() {
  /**
   * @type {HTMLElement}
   */
  const playerBox = document.querySelector('#player-box');
  const width = playerBox.clientHeight * (16 / 9);
  playerBox.style.width = width.toString() + 'px';
}
